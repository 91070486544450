<!-- 排名 -->
<template>
  <div class="ranking_box_css">
    <div class="year_css">
      <div class="year_img_css">{{ year }}级</div>
    </div>

    <div class="item_box_css">
      <div v-if="rankingList < 4">
        <div
          class="ranking_item_css"
          v-for="(item, index) in rankingList"
          :key="item.userID"
        >
          <div class="ranking_index_css">
            <img v-if="index == 0" src="@/assets/largeScreen/diyiming.png" />
            <img
              v-else-if="index == 1"
              src="@/assets/largeScreen/dierming_1.png"
            />
            <img
              v-else-if="index == 2"
              src="@/assets/largeScreen/disanming_1.png"
            />
            <div v-else>{{ index + 1 }}</div>
          </div>
          <img
            class="ranking_image_css"
            :src="item.photo"
            @error="imgError(index)"
          />
          <div class="width_css">
            <div class="ranking_userName_css">{{ item.userName }}</div>
            <div class="ranking_className_css">
              {{ getClass(item.className) }}
            </div>
          </div>
          <div class="ranking_zanCount_css">{{ getUnit(item.Score) }}</div>
        </div>
      </div>
      <vue-seamless-scroll
        v-else
        :data="rankingList"
        :class-option="optionSingleHeightTime"
      >
        <div
          class="ranking_item_css"
          v-for="(item, index) in rankingList"
          :key="item.userID"
        >
          <div class="ranking_index_css">
            <img v-if="index == 0" src="@/assets/largeScreen/diyiming.png" />
            <img
              v-else-if="index == 1"
              src="@/assets/largeScreen/dierming_1.png"
            />
            <img
              v-else-if="index == 2"
              src="@/assets/largeScreen/disanming_1.png"
            />
            <div v-else>{{ index + 1 }}</div>
          </div>
          <img
            class="ranking_image_css"
            :src="item.photo"
            @error="imgError(index)"
          />
          <div class="width_css">
            <div class="ranking_userName_css">{{ item.userName }}</div>
            <div class="ranking_className_css">
              {{ getClass(item.className) }}
            </div>
          </div>
          <div class="ranking_zanCount_css">{{ getUnit(item.Score) }}</div>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>
<script>
import { StudentScoreRankingReport } from '@/api/index.js'
import vueSeamlessScroll from 'vue-seamless-scroll'
import moment from 'moment'
// 处理无头像显示
import { fillPhoto } from '@/utils/photoList'
import getAva from '@/utils/photoList'
import toChinese from './toChinese'
export default {
  props: ['year'],
  components: { vueSeamlessScroll },
  data() {
    return {
      rankingList: [],
      time: '',
      showE: true,
      timer: null, // 定时器名称
    }
  },
  computed: {
    optionSingleHeightTime() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      }
    },
  },
  created() {
    this.time = localStorage.getItem('newTime') || moment().format('YYYY-MM-DD')
    this.getStudentScoreRankingReport()
    this.timer = setInterval(() => {
      this.getStudentScoreRankingReport()
    }, 1000 * 60 * 60)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    getStudentScoreRankingReport() {
      let GradeValue = 1
      let ya = Number(moment().format('YYYY'))
      let mm = Number(moment().format('MM'))
      let num = 6
      this.gradeList = []
      if (mm < 9) {
        GradeValue = ya - Number(this.year)
      } else {
        GradeValue = ya - Number(this.year) + 1
      }
      this.showE = true
      StudentScoreRankingReport({
        top: 10,
        Grade: GradeValue,
        startDate: moment()
          .subtract(30, 'days')
          .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      }).then((res) => {
        this.rankingList = fillPhoto(res.data)
        if (res.data.length == 0) {
          this.showE = false
        } else {
          this.showE = true
        }
      })
    },
    getUnit(e) {
      let size = Number(e)
      if (size < 10000) {
        return size
      } else if (size < 10000 * 1000) {
        return (size / 10000).toFixed(2) + '万'
      } else {
        return (size / (10000 * 1000)).toFixed(2) + '千万'
      }
    },
    getClass(e) {
      let vn = e.split('级')[1]
      let sn = toChinese(Number(vn.slice(0, -1)))
      return sn + '班'
    },
    imgError(idx) {
      this.rankingList[idx].photo = getAva()
    },
  },
}
</script>
<style>
.slide-move {
  transition: all 0.8s ease;
}
.slide-leave-active {
  position: absolute;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  /* transform: scale(0.5); */
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 2s;
}
</style>
<style scoped>
.ranking_box_css {
  width: 300px;
  margin-right: 15px;
  position: relative;
}
.bg_img_css {
  width: 440px;
  position: absolute;
  left: -43px;
  top: -41px;
}
.item_box_css {
  height: 186px;
  overflow: hidden;
  border-radius: 10px;
  background-color: #ffffffdc;
  box-shadow: #f0f0f091 0px 2px 10px;
}
.ranking_item_css {
  display: flex;
  align-items: center;
  padding: 10px 16px;
}
/* .ranking_item_css:nth-child(2n){
    background-color: #ffffffa9;
} */
.ranking_image_css {
  width: 35px;
  height: 35px;
  border-radius: 1000px;
  flex-shrink: 0;
  margin-right: 10px;
  object-fit: cover;
}
.ranking_index_css {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  text-align: center;
}
.ranking_index_css > img {
  width: 30px;
  height: 30px;
}
.ranking_userName_css {
  font-weight: bold;
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}
.ranking_className_css {
  font-size: 16px;
  color: #999;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}
.ranking_zanCount_css {
  font-weight: bold;
  font-size: 16px;
  flex-shrink: 0;
  margin-left: 10px;
}
.width_css {
  flex-grow: 1;
}
.year_css {
  border-radius: 10px 10px 0 0;
  background-color: #ffffffdc;
  width: 70%;
  margin: 0 auto -1px;
  padding: 0px 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.year_img_css {
  /* height: 22px; */
  font-family: 'wryh';
  color: #6647ee;
  font-size: 32px;
  /* letter-spacing:2px; */
}
.year_text_img_css {
  height: 28px;
}
</style>
