<template>
  <div class="pages_Css">
    <!-- 总排名 -->
    <ranking class="ranking_css" />
    <!-- 今日之星 -->
    <todayStar class="todayStar_css" />
    <!-- 实时点评 -->
    <realTime class="realTime_css" />
    <!-- 饼图 -->
    <metaPie class="metaPie_css" />
    <!-- 教评 -->
    <teachingEvaluation class="teachingEvaluation_css" />

    <div class="box_flex_css">
      <more v-for="item in gradeList" :key="item" :year="item" />
    </div>
  </div>
</template>
<script>
import moment from "moment";
import ranking from "./ranking.vue";
import more from "./more.vue";
import metaPie from "./metaPie.vue";
import realTime from "./realTime.vue";
import teachingEvaluation from "./teachingEvaluation.vue";
import todayStar from "./todayStar.vue";
export default {
  components: {
    ranking,
    more,
    metaPie,
    realTime,
    teachingEvaluation,
    todayStar,
  },
  data() {
    return {
      moment: moment,
      gradeList:[],
    };
  },
  created() {
    let ya = moment().format("YYYY");
    let mm = Number(moment().format("MM"));
    let num = 6;
    this.gradeList = [];
    if (mm < 9) {
      for (let i = 0; i < num; i++) {
        this.gradeList.push(Number(ya) - i - 1);
      }
    } else {
      for (let i = 0; i < num; i++) {
        this.gradeList.push(Number(ya) - i);
      }
    }
    console.log(this.gradeList)
  },
};
</script>
<style>
@font-face {
  font-family: "wryh";
  src: url(../../assets/largeScreen/20190301111201.ttf);
}
</style>
<style scoped>
.pages_Css {
  position: fixed !important;
  top: 1.5vh;
  right: 1.8vw;
  bottom: 15px;
  left: 1.8vw;
  user-select: none;
}
.todayStar_css {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}
.realTime_css {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
.ranking_css {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}
.metaPie_css {
  position: absolute;
  top: 465px;
  left: 408px;
  z-index: 3;
}
.teachingEvaluation_css {
  position: absolute;
  top: 465px;
  right: 0;
  z-index: 3;
}
.box_flex_css {
  display: flex;
  position: absolute;
  bottom: 0;
  right: -15px;
  left: 0;
  z-index: 3;
}
</style>
