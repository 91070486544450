<!-- 排名 -->
<template>
  <div class="realTime_box_css">
    <div class="item_box_css">
      <div class="box_imgpp_Css">
        <div class="img42838e36d868e4ef_Css">实时评价</div>
        <!-- <img src="@/assets/largeScreen/563322555ssc145sa1.png" /> -->
        <img
          class="imgpaihangbang_Css"
          src="@/assets/largeScreen/paiming5632265522323.png"
        />
      </div>
      <transition-group name="slide">
        <div
          class="realTime_item_css"
          v-for="(item, index) in realTimeList"
          :key="item.userID"
        >
          <img
            class="realTime_photo_css"
            :src="item.photo"
            @error="imgError(index)"
          />
          <div class="width_css">
            <div>
              <div class="realTime_userName_css">{{ item.userName }}</div>
              <div class="realTime_score_css">
                <img
                  v-for="it in Number(item.Score)"
                  :key="it"
                  src="@/assets/largeScreen/xingxing.png"
                />
              </div>
            </div>
            <div class="realTime_evaluate_css">
              <div v-for="(ibb, idx) in item.Title" :key="idx">{{ ibb }}</div>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>
<script>
import getAva from '@/utils/photoList'
// 处理无头像显示
import { fillPhoto } from '@/utils/photoList'
let setInte = null
let setInteA = null
import { GetNewEvaluationData } from '@/api/index.js'
import moment from 'moment'
export default {
  data() {
    return {
      realTimeList: [],
      allList: [],
      idxS: 0,
    }
  },
  created() {
    this.getGetNewEvaluationData(1)
  },
  beforeDestroy() {
    if (setInteA) {
      clearInterval(setInteA)
    }
    if (setInte) {
      clearInterval(setInte)
    }
  },
  methods: {
    // 生成GUID
    newGuid() {
      function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
      }
      return S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4()
    },
    getGetNewEvaluationData(e) {
      GetNewEvaluationData({
        top: 200,
        Grade: 0,
      }).then((res) => {
        this.allList = fillPhoto(res.data)
        for (let i = 0; i < this.allList.length; i++) {
          this.allList[i].userID = this.allList[i].userID + this.newGuid()
        }
        if (e == 1) {
          this.realTimeList = this.allList.slice(0, 20)
          this.idxS = 20
        } else {
          this.idxS = 0
        }
        if (setInte) {
          clearInterval(setInte)
        }
        if (setInteA) {
          clearInterval(setInteA)
        }
        if (this.allList.length > 20) {
          this.setIn()
        } else {
          setInteA = setInterval(() => {
            this.getGetNewEvaluationData(2)
          }, 1000 * 60 * 2)
        }
      })
    },
    setIn() {
      setInte = setInterval(() => {
        let num = Math.floor(Math.random() * 2 + 1)
        for (let i = 0; i < num; i++) {
          this.idxS += 1
          this.realTimeList.unshift(this.allList[this.idxS])
        }
        if (this.realTimeList.length > 20) {
          this.realTimeList.splice(20, 10)
        }
        if (this.idxS >= this.allList.length - 20) {
          clearInterval(setInte)
          this.getGetNewEvaluationData(2)
        }
      }, Math.floor(Math.random() * 5 + 1) * 1000 - 800)
    },
    imgError(idx) {
      this.realTimeList[idx].photo = getAva()
    },
  },
}
</script>
<style>
.slide-move {
  transition: all 0.8s ease;
}
.slide-leave-active {
  position: absolute;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  /* transform: scale(0.5); */
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 2s;
}
</style>
<style scoped>
.realTime_box_css {
  width: 394px;
}
.box_imgpp_Css {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px 0 12px;
}
.img42838e36d868e4ef_Css {
  height: 70px;
  font-family: 'wryh';
  color: #6647ee;
  font-size: 60px;
  letter-spacing: -3px;
}
.imgpaihangbang_Css {
  height: 45px;
  margin-top: -5px;
}
.item_box_css {
  height: 816px;
  overflow: hidden;
  border-radius: 10px;
  background-color: #ffffffdc;
}
.realTime_item_css {
  display: flex;
  align-items: center;
  padding: 6px 8px 6px 16px;
}
.realTime_photo_css {
  width: 45px;
  height: 45px;
  border-radius: 1000px;
  flex-shrink: 0;
  margin-right: 10px;
  object-fit: cover;
}
.realTime_userName_css {
  font-weight: bold;
  flex-grow: 1;
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}
.width_css {
  /* display: flex;
    align-items: center; */
  width: 100%;
}
.width_css > div:nth-child(1) {
  /* flex-grow: 1; */
  display: flex;
  align-items: center;
  padding-right: 7px;
}
.realTime_score_css {
  display: flex;
  align-items: center;
}
.realTime_score_css > img {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}
.realTime_evaluate_css {
  display: flex;
  align-items: center;
  margin-top: 4px;
  overflow: hidden;
}
.realTime_evaluate_css > div {
  padding: 1px 6px;
  border-radius: 4px;
  border: #9868f1dc 1px solid;
  color: #9868f1dc;
  font-size: 14px;
  margin-right: 6px;
  flex-shrink: 0;
}
</style>
