<template>
  <div class="box_css">
    <div class="jiaopingpaiming_css">
      教评榜
    </div>
    <div class="item_box_Css">
      <div v-for="(item, index) in teachingList.slice(0, 8)" :key="index">
        <img
          :key="imageKey"
          class="realTime_photo_css"
          :src="item.photo"
          @error="imgError(index)"
        />
        <div class="userName_css">
          {{ item.userName }}
        </div>
        <div class="number_css">
          {{ Number(item.allCount) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import getAva from '@/utils/photoList'
// 处理无头像显示
import { fillPhoto } from '@/utils/photoList'
import { EvaluatorScoreRankingReport } from '@/api/index.js'
export default {
  data() {
    return {
      teachingList: [],
      newUrl:
        'https://profile-avatar.csdnimg.cn/582cbc4d0ea040babe9b5c96a399d8b7_gik99.jpg!1',
      time: '',
      showE: true,
      timer: null, // 定时器名称
      imageKey: 0, // 初始key值
    }
  },
  created() {
    this.time = localStorage.getItem('newTime') || moment().format('YYYY-MM-DD')
    this.getEvaluatorScoreRankingReport3()
    this.timer = setInterval(() => {
      this.getEvaluatorScoreRankingReport3()
    }, 1000 * 60 * 60)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },

  methods: {
    // 教师
    getEvaluatorScoreRankingReport3() {
      this.showE = true
      EvaluatorScoreRankingReport({
        top: 8,
        Grade: 0,
        startDate: moment()
          .subtract(30, 'days')
          .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        Evaltype: 3,
      }).then((res) => {
        this.teachingList = fillPhoto(res.data)
        this.sliceTeachingList = this.teachingList.slice(0, 8)
        if (res.data.length == 0) {
          this.showE = false
        } else {
          this.showE = true
        }
      })
    },
    imgError(idx) {
      this.teachingList[idx].photo = getAva()
    },
  },
}
</script>

<style scoped>
.bg_img_css {
  position: absolute;
  width: 600px;
  top: 0;
  right: 0;
  left: 0;
}
.box_css {
  /* width: 600px; */
  position: relative;
  width: 390px;
  background-color: #ffffffdc;
  border-radius: 15px;
  overflow: hidden;
  height: 350px;
}
.item_box_Css {
  position: relative;
  z-index: 99;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 15px 0;
}
.item_box_Css > div {
  padding-top: 16px;
  width: 23%;
  height: 135px;
  background-color: #ffffffaf;
  border-radius: 10px;
  margin-right: 2.3%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: #f3f3f3 1px 5px 15px;
}
.item_box_Css > div:nth-child(4n) {
  margin-right: 0;
}
.realTime_photo_css {
  width: 52px;
  height: 52px;
  border-radius: 1000px;
  flex-shrink: 0;
  object-fit: cover;
}
.userName_css {
  font-size: 16px;
  margin: 6px 0;
}
.number_css {
  font-size: 18px;
  font-weight: bold;
}
.jiaopingpaiming_css {
  padding: 10px 20px 0;
  font-family: 'wryh';
  color: #6647ee;
  font-size: 38px;
  /* letter-spacing:0px; */
}
.jiaopingpaiming_css > img {
  height: 35px;
}
</style>
