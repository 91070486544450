<!-- 排名 -->
<template>
  <div class="ranking_box_css">
    <div class="box_imgpp_Css">
      <div class="img42838e36d868e4ef_Css">总分榜</div>
      <!-- <img src="@/assets/largeScreen/42838e36d868e4ef793ed0eeb5bf0ef1.png" /> -->
      <img
        class="imgpaihangbang_Css"
        src="@/assets/largeScreen/paihangbang.png"
      />
    </div>
    <div class="flex_css">
      <div v-if="rankingList[1]">
        <div class="top_img_css">
          <img :src="rankingList[1].photo" @error="imgError(1)" />
          <img src="@/assets/largeScreen/dierming.png" />
        </div>
        <div class="userName_box_css">
          <div>{{ rankingList[1].userName }}</div>
          <div>{{ getUnit(rankingList[1].Score) }}</div>
        </div>
      </div>
      <div v-else></div>
      <div class="one_box_css" v-if="rankingList[0]">
        <div class="top_img_css one_img_css">
          <img :src="rankingList[0].photo" @error="imgError(0)" />
          <img src="@/assets/largeScreen/diyiming_2.png" />
        </div>
        <div class="userName_box_css">
          <div>{{ rankingList[0].userName }}</div>
          <div>{{ getUnit(rankingList[0].Score) }}</div>
        </div>
      </div>
      <div v-else></div>
      <div v-if="rankingList[2]">
        <div class="top_img_css">
          <img :src="rankingList[2].photo" @error="imgError(2)" />
          <img src="@/assets/largeScreen/disanming.png" />
        </div>
        <div class="userName_box_css">
          <div>{{ rankingList[2].userName }}</div>
          <div>{{ getUnit(rankingList[2].Score) }}</div>
        </div>
      </div>
      <div v-else></div>
    </div>
    <transition-group name="slide">
      <div
        class="ranking_item_css"
        v-for="(item, index) in rankingList.slice(3, 8)"
        :key="item.userID"
      >
        <div class="ranking_index_css">
          {{ index + 4 }}
        </div>
        <img
          class="ranking_image_css"
          :src="item.photo"
          @error="imgError(index + 3)"
        />
        <div class="width_css">
          <div class="ranking_userName_css">{{ item.userName }}</div>
          <div class="ranking_className_css">{{ item.className }}</div>
        </div>
        <div class="ranking_zanCount_css">{{ getUnit(item.Score) }}</div>
      </div>
    </transition-group>
  </div>
</template>
<script>
import getAva from '@/utils/photoList'
// 处理无头像显示
import { fillPhoto } from '@/utils/photoList'
import moment from 'moment'
import { StudentScoreRankingReport } from '@/api/index.js'
export default {
  data() {
    return {
      rankingList: [{ Score: 0 }, { Score: 0 }, { Score: 0 }],
      showE: true,
      time: '',
      timer: null, // 定时器名称
    }
  },
  created() {
    this.time = localStorage.getItem('newTime') || moment().format('YYYY-MM-DD')
    this.getStudentScoreRankingReport()
    this.timer = setInterval(() => {
      this.getStudentScoreRankingReport()
    }, 1000 * 60 * 60)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    getStudentScoreRankingReport() {
      this.showE = true
      StudentScoreRankingReport({
        top: 9,
        Grade: 0,
        startDate: moment()
          .subtract(30, 'days')
          .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      }).then((res) => {
        let numIndex = -1
        this.rankingList = fillPhoto(res.data)
        if (res.data.length == 0) {
          this.showE = false
        } else {
          this.showE = true
        }
      })
    },
    getUnit(e) {
      let size = Number(e)
      if (size < 10000) {
        return size
      } else if (size < 10000 * 1000) {
        return (size / 10000).toFixed(2) + '万'
      } else {
        return (size / (10000 * 1000)).toFixed(2) + '千万'
      }
    },
    imgError(idx) {
      console.log(idx, this.rankingList)
      this.rankingList[idx].photo = getAva()
      console.log(this.rankingList)
    },
  },
}
</script>
<style>
.slide-move {
  transition: all 0.8s ease;
}
.slide-leave-active {
  position: absolute;
}
</style>
<style scoped>
.ranking_box_css {
  width: 394px;
  height: 450px;
  background-color: #ffffffdc;
  border-radius: 15px;
  overflow: hidden;
}
.box_imgpp_Css {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px 0 12px;
}
.img42838e36d868e4ef_Css {
  height: 70px;
  font-family: 'wryh';
  color: #6647ee;
  font-size: 60px;
  letter-spacing: -5px;
}
.imgpaihangbang_Css {
  height: 70px;
}
.ranking_item_css {
  display: flex;
  align-items: center;
  padding: 6px 16px;
}
.ranking_item_css:nth-child(2n) {
  background-color: #ffffffa9;
}
.ranking_image_css {
  width: 35px;
  height: 35px;
  border-radius: 1000px;
  flex-shrink: 0;
  margin-right: 10px;
  object-fit: cover;
}
.ranking_index_css {
  flex-shrink: 0;
  width: 40px;
  color: #d1d1d1;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  padding-right: 10px;
}
.ranking_userName_css {
  font-weight: bold;
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}
.ranking_className_css {
  font-size: 15px;
  color: #999;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}
.ranking_zanCount_css {
  font-weight: bold;
  font-size: 16px;
  flex-shrink: 0;
  margin-left: 10px;
}
.width_css {
  flex-grow: 1;
}
.top_img_css {
  position: relative;
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.top_img_css > img:nth-child(1) {
  width: 50px;
  height: 50px;
  border-radius: 1000px;
  object-fit: cover;
}
.top_img_css > img:nth-child(2) {
  width: 30px;
  height: 30px;
  position: absolute;
  top: -13px;
  left: -13px;
  z-index: 9;
  object-fit: cover;
}
.one_img_css {
  width: 60px;
  height: 60px;
}
.one_img_css > img:nth-child(1) {
  width: 60px;
  height: 60px;
  border-radius: 1000px;
}
.one_img_css > img:nth-child(2) {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -18px;
  left: -18px;
  z-index: 9;
}
.one_box_css {
  margin-top: -26px;
}
.flex_css {
  display: flex;
  justify-content: space-between;
  padding: 20px 15px 0;
  position: relative;
}
.flex_css > div {
  flex-shrink: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.userName_box_css > div:nth-child(1) {
  font-weight: bold;
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}
.userName_box_css > div:nth-child(2) {
  font-weight: bold;
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}
</style>
