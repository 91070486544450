<template>
  <div class="ranking_box_css">
    <div class="logo_img_css">
      <img src="@/assets/largeScreen/20230914193418.png" />
    </div>
    <div class="imgb6fe8fec7178424c68d71c43b7fa81d_css">全校德智体美劳评价及各年级学生数量</div>
    <!-- <img class="imgb6fe8fec7178424c68d71c43b7fa81d_css" src="@/assets/largeScreen/b6fe8fec7178424c68d71c43b7fa81d1.png" /> -->
    <div id="metaPie" class="metaPiemetaPieCss"></div>
    <div id="schoolPopulation" class="schoolPopulationschoolPopulation"></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { SchoolReportGrowthTrends2, GradeReportGrowthTrends } from "@/api/index.js";
let myChart = null;
let schoolPop = null;
export default {
  data() {
    return {
      valArr: [],
      timer: null  // 定时器名称
    };
  },
  created() {
    this.getSchoolReportGrowthTrends2();
    this.getGradeReportGrowthTrends();
    this.timer = setInterval(() => {
      this.getSchoolReportGrowthTrends2();
      this.getGradeReportGrowthTrends();
    }, 1000 * 60 * 60);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getSchoolReportGrowthTrends2() {
      SchoolReportGrowthTrends2({
        startDate: "",
        endDate: "",
      }).then((res) => {
        let listArr = [
          { ttID: "1", Score: 0 },
          { ttID: "2", Score: 0 },
          { ttID: "3", Score: 0 },
          { ttID: "4", Score: 0 },
          { ttID: "5", Score: 0 },
        ];
        for (let i = 0; i < listArr.length; i++) {
          for (let j = 0; j < res.data.length; j++) {
            if (listArr[i].ttID == res.data[j].ttID) {
              listArr[i].Score = Number(res.data[j].Score);
              break;
            }
          }
        }
        this.valArr = listArr.map(function(obj) {
          return obj.Score;
        });
        this.metaPie();
      });
    },
    // 获取全校德智体美劳
    getGradeReportGrowthTrends() {
      GradeReportGrowthTrends({
        iType: 4,
        startDate: "",
        endDate: "",
      }).then((res) => {
        this.schoolPopulation(res.data);
        // this.listArr = res.data;
      });
    },
    async metaPie() {
      if (myChart) {
        myChart.dispose();
      }

      myChart = null;
      this.$forceUpdate();
      await new Promise((res) => setTimeout(res, 50));
      myChart = echarts.init(document.getElementById("metaPie"));
      let chartData = [
        { value: this.valArr[0], name: "德" },
        { value: this.valArr[1], name: "智" },
        { value: this.valArr[2], name: "体" },
        { value: this.valArr[3], name: "美" },
        { value: this.valArr[4], name: "劳" },
      ];
      // 绘制图表
      myChart.setOption({
        legend: {
          orient: "vertical",
          top: "23%",
          right: 10,
          itemWidth: 32,
          itemHeight: 16,
          itemGap: 20,
          formatter: function(name) {
            const data = chartData;
            let total = 0;
            let target;
            for (let i = 0, l = data.length; i < l; i++) {
              total += data[i].value;
              if (data[i].name == name) {
                target = data[i].value;
              }
            }
            const percent = ((target / total) * 100).toFixed(1);
            return `${name}  ${target}(${percent}%)`;
            // return `${name}  ${target}条\n占比${percent}%`;
          },
          textStyle: {
            color: "#555",
            fontSize: "16px",
          },
        },
        toolbox: { show: false },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            radius: ["30%", "92%"],
            center: ["33.5%", "50%"],
            roseType: "area",
            label: {
              show: true,
              normal: {
                position: "inner",
                color: "#fff",
                fontSize: "20px",
              },
            },
            labelLine: { show: false },
            itemStyle: {
              borderRadius: 8,
            },
            data: chartData,
          },
        ],
      });
    },
    async schoolPopulation(data) {
      if (schoolPop) {
        schoolPop.dispose();
      }

      schoolPop = null;
      this.$forceUpdate();
      //   data.Charts.Series[i].seriesData
      let dataArr = [0, 0, 0, 0, 0, 0];
      for (let i = 1; i < data.length; i++) {
        // data[i].Charts.Series[i].seriesData
        data[i].Charts.Series.map((val) => {
          val.seriesData.map((bx) => {
            dataArr[i - 1] += bx;
          });
        });
      }
      await new Promise((res) => setTimeout(res, 50));
      schoolPop = echarts.init(document.getElementById("schoolPopulation"));
      let yearArr = ["一年级", "二年级", "三年级", "四年级", "五年级", "六年级"];
      // 绘制图表
      schoolPop.setOption({
        tooltip: {
          trigger: "item",
        },

        grid: { left: 0, top: 20, bottom: 10, right: 20, containLabel: true },
        xAxis: {
          type: "category",
          data: yearArr,
          axisLine: { lineStyle: { color: "#ccc" } },
          axisTick: { length: 3 },
          axisLabel: { color: "#555" },
        },
        yAxis: {
          type: "value",
          axisLine: { show: true, lineStyle: { color: "#ccc" } },
          axisLabel: { color: "#555" },
          splitLine: { lineStyle: { color: ["#d6e4f1"], type: [5, 8], dashOffset: 3 } },
        },
        series: [
          {
            type: "bar",
            barWidth: 44,
            itemStyle: {
              borderRadius: [10, 10, 0, 0],
              color: function(params) {
                // 定义一个颜色集合
                let colorList = ["#52A8FF", "#00B389", "#FFA940", "#FF5A57", "#29EFC4", "#F8AEA4", "#FFC53D", "#009982", "#C099FC", "#F5855F"];
                // 对每个bar显示一种颜色
                return colorList[params.dataIndex];
              },
            },
            label: { show: true, position: "insideTop", offset: [0, 10], color: "#fff" },
            data: dataArr,
          },
        ],
      });
    },
  },
};
</script>
<style scoped>
.ranking_box_css {
  width: 1030px;
  height: 350px;
  background-color: #ffffffdc;
  border-radius: 15px;
  display: flex;
  position: relative;
  padding-top: 50px;
  box-sizing: border-box;
}
.logo_img_css {
  position: absolute;
  left: 171px;
  top: 200px;
  transform: translate(-50%, -50%);
  z-index: 10;
  width: 84px;
  height: 84px;
  border-radius: 1000px;
}
.logo_img_css > img {
  width: 84px;
  height: 84px;
  border-radius: 1000px;
  transition: all 0.5s;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.logo_img_css > img:hover {
  width: 98px;
  height: 98px;
}
.imgb6fe8fec7178424c68d71c43b7fa81d_css {
  position: absolute;
  top: 6px;
  height: 40px;
  text-align: center;
  left: 0;
  right: 0;
  /* left: 50%;
    transform: translateX(-50%); */
  font-family: "wryh";
  color: #6647ee;
  font-size: 38px;
  /* letter-spacing:10px; */
}
.metaPiemetaPieCss {
  width: 510px;
  height: 300px;
}
.schoolPopulationschoolPopulation {
  width: 505px;
  height: 300px;
  margin-left: 15px;
}
</style>
