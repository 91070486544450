<!-- 今日之星 -->
<template>
  <div class="ranking_box_css">
    <div class="box_imgpp_Css">
      <img
        class="starA_css"
        style="transform: scaleX(-1);"
        src="@/assets/largeScreen/star55656523.png"
      />
      <div class="img42838e36d868e4ef_Css">今日之星</div>
      <!-- <img class="img42838e36d868e4ef_Css" src="@/assets/largeScreen/42838e36d868sklasjdsdja1.png" /> -->
      <img class="starA_css" src="@/assets/largeScreen/star55656523.png" />
    </div>
    <div class="flex_css">
      <div v-for="(item, index) in rankingList" :key="item.userId">
        <div class="top_img_css">
          <img :src="item.photo" @error="imgError(index)" />
          <div>
            <img v-if="index == 0" src="@/assets/largeScreen/yiyi.png" />
            <img v-if="index == 1" src="@/assets/largeScreen/erer.png" />
            <img v-if="index == 2" src="@/assets/largeScreen/sansan.png" />
            <img v-if="index > 2" src="@/assets/largeScreen/sisi.png" />
            <div class="pai_index_css">
              {{ index + 1 }}
            </div>
          </div>
        </div>
        <div class="userName_box_css">
          {{ item.userName }}
        </div>
      </div>
    </div>
    <div class="zanwu_css-dv" v-if="!showE">
      暂无内容
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { StudentScoreRankingReport } from '@/api/index.js'
import getAva from '@/utils/photoList'
// 处理无头像显示
import { fillPhoto } from '@/utils/photoList'
export default {
  data() {
    return {
      rankingList: [],
      showE: true,
      time: '',
      timer: null, // 定时器名称
    }
  },
  created() {
    this.time = localStorage.getItem('newTime') || moment().format('YYYY-MM-DD')
    this.getStudentScoreRankingReport()
    this.timer = setInterval(() => {
      this.getStudentScoreRankingReport()
    }, 1000 * 60 * 60)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  methods: {
    getStudentScoreRankingReport() {
      this.showE = true
      StudentScoreRankingReport({
        top: 30,
        Grade: 0,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      }).then((res) => {
        this.rankingList = fillPhoto(res.data)
        if (res.data.length == 0) {
          this.showE = false
        } else {
          this.showE = true
        }
      })
    },
    imgError(idx) {
      this.rankingList[idx].photo = getAva()
    },
  },
}
</script>
<style scoped>
.ranking_box_css {
  width: 1030px;
  background-color: #ffffffdc;
  border-radius: 15px;
  overflow: hidden;
  height: 450px;
  /* transform: scale(6); */
}
.box_imgpp_Css {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px 0 8px;
}
.img42838e36d868e4ef_Css {
  height: 70px;
  margin: 0 12px;
  font-family: 'wryh';
  color: #6647ee;
  font-size: 60px;
  letter-spacing: 10px;
}
.starA_css {
  height: 70px;
}
.flex_css {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 15px 0;
  position: relative;
}
.flex_css > div {
  flex-shrink: 0;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;
}
.userName_box_css {
  font-weight: bold;
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  word-break: break-all;
  word-wrap: break-word;
}
.top_img_css {
  position: relative;
  width: 90px;
  height: 90px;
  object-fit: cover;
}
.top_img_css > img {
  width: 63px;
  height: 74px;
  object-fit: cover;
  clip-path: polygon(50% 0%, 100% 26%, 100% 72%, 50% 100%, 0% 72%, 0 26%);
  overflow: hidden;
  position: relative;
  top: 1px;
  left: 1px;
}
.top_img_css > div > img {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  object-fit: cover;
}
.pai_index_css {
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 15px;
  z-index: 19;
  font-weight: bold;
}
.zanwu_css-dv {
  font-family: 'wryh';
  color: #6647ee;
  font-size: 50px;
  letter-spacing: 10px;
  text-align: center;
  line-height: 320px;
}
</style>
